import React from 'react';
import { navigate } from 'gatsby';
import styled from 'styled-components';
import { breakpointQuery, standardColours, fontSize } from '../utilities';
import Animation from './Animation';
import ButtonLink from './ButtonLink';

const StyledProductServiceBlock = styled.article`
  padding: 20px;
  height: 100%;
  border: 1px solid ${standardColours.lighterGrey};
  border-radius: 10px;
  cursor: pointer;
`;

const StyledProductServiceBlockInner = styled.div`
  ${breakpointQuery.tsmall`
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
  `}
`;

const StyledProductServiceBlockImage = styled.img`
  margin-bottom: 20px;
  max-height: 160px;
  max-width: 160px;

  ${breakpointQuery.tsmall`
    margin-bottom: 0;
    margin-left: 20px;
  `}

  ${breakpointQuery.large`
    margin-left: 25px;
  `}
`;

const StyledProductServiceBlockContent = styled.div``;

const StyledProductServiceBlockHeading = styled.h3`
  ${fontSize(23)};

  ${breakpointQuery.tiny`
    ${fontSize(26)};
  `}

  ${breakpointQuery.medium`
    ${fontSize(29)};
  `}

  ${breakpointQuery.large`
    ${fontSize(32)};
  `}
`;

const StyledProductServiceBlockText = styled.p`
  margin: 14px 0;
  line-height: 1.7;

  ${breakpointQuery.large`
    margin-top: 16px;
    margin-bottom: 16px;
  `}
`;

const ProductServiceBlock = ({
  data: {
    title,
    introductionText,
    image: { url, alt },
    slug,
  },
}) => {
  return (
    <Animation>
      <StyledProductServiceBlock
        onClick={() => navigate(`/products-services/${slug}/`)}
      >
        <StyledProductServiceBlockInner>
          <StyledProductServiceBlockImage src={url} loading="lazy" alt={alt} />
          <StyledProductServiceBlockContent>
            <StyledProductServiceBlockHeading>
              {title}
            </StyledProductServiceBlockHeading>
            <StyledProductServiceBlockText>
              {introductionText}
            </StyledProductServiceBlockText>
            <ButtonLink to={`/products-services/${slug}/`}>
              Read more
            </ButtonLink>
          </StyledProductServiceBlockContent>
        </StyledProductServiceBlockInner>
      </StyledProductServiceBlock>
    </Animation>
  );
};

export default ProductServiceBlock;
