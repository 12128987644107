import React from 'react';
import { graphql } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import ProductsServicesList from '../components/ProductsServicesList';
import Divider from '../components/Divider';

const ProductsServicesPage = ({
  data: {
    datoCmsBannerImage: { productsServicesBannerImage },
    allDatoCmsProductService: { nodes },
  },
}) => (
  <Layout>
    <HelmetDatoCms title="Products &amp; Services | Barcanet" />
    <main>
      <Banner
        heading="Products &amp; Services"
        image={productsServicesBannerImage}
      />
      <ProductsServicesList items={nodes} />
      <Divider />
    </main>
  </Layout>
);

export const ProductsServicesPageQuery = graphql`
  query ProductsServicesPageQuery {
    datoCmsBannerImage {
      productsServicesBannerImage {
        ...BannerImageFragment
      }
    }
    allDatoCmsProductService(
      filter: {
        id: {
          nin: [
            "DatoCmsProductService-3249738-en"
            "DatoCmsProductService-5299840-en"
          ]
        }
      }
      sort: { fields: position }
    ) {
      nodes {
        id
        image {
          url
          alt
        }
        title
        introductionText
        slug
      }
    }
  }
`;

export default ProductsServicesPage;
