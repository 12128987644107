import React from 'react';
import styled from 'styled-components';
import { breakpointQuery, sectionMargins } from '../utilities';
import SectionHeader from './SectionHeader';
import Container from './Container';
import ProductServiceBlock from './ProductServiceBlock';

const StyledProductsServicesList = styled.section`
  ${sectionMargins};
`;

const StyledProductsServicesListInner = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: 20px;

  ${breakpointQuery.small`
    grid-gap: 30px;
    margin-top: 30px;
  `}

  ${breakpointQuery.medium`
    grid-template-columns: repeat(2, 1fr);
  `}

  ${breakpointQuery.large`
    grid-gap: 40px;
    margin-top: 40px;
  `}
`;

const ProductsServicesList = ({ heading, text, items }) => {
  return (
    <StyledProductsServicesList>
      {heading && <SectionHeader heading={heading} text={text} />}
      <Container>
        <StyledProductsServicesListInner>
          {items.map(item => (
            <ProductServiceBlock key={item.id} data={item} />
          ))}
        </StyledProductsServicesListInner>
      </Container>
    </StyledProductsServicesList>
  );
};

export default ProductsServicesList;
